<template>
    <div class="withdrawl">
        <a-form-item>
            <span class="label" slot="label">{{$t('wallet.withdrawl.account')}}</span>
            <a-input class="bg-sec" size="large" :value="address" disabled />
        </a-form-item>
        <a-form-item>
            <div class="label flex" slot="label">
              <span>{{$t('wallet.withdrawl.amount')}}</span>
              <span class="color-gray">
                {{$t('wallet.withdrawl.canuse')}} 
                <i>{{$store.state.userinfo.balance/1000000}}</i>
              </span>
            </div>
            <a-input size="large" ref="amount" v-model="withdrawl_amount">
              <span class="color-gray" slot="suffix" @click="withdrawl_amount=$store.state.userinfo.balance/1000000">MAX</span>
            </a-input>
        </a-form-item>
        <!-- <a-form-item>
            <div class="label flex" slot="label">
              <span>{{$t('wallet.withdrawl.password')}}</span>
              <span @click="forgetPass" class="color-gray">
                {{$t('withdrawl.forget')}}
              </span>
            </div>
            <a-input-password size="large" ref="fund_password" v-model="fund_password" />
        </a-form-item> -->
        <div class="btn-primary mg30" @click="withdrawl">
            {{$t('common.submit')}}
        </div>
    </div>
</template>
<script>

export default {
  name: 'Withdrawl',
  data(){
    return {
      address: this.$store.state.userinfo.address,
      withdrawl_amount: 0.00,
      // fund_password:'',
      // wtype:0,
    }
  },
  components:{
      
  },
  methods:{
    // forgetPass(){
    //   this.$message.info(this.$t('withdrawl.forgetPassTips'));

    // },
    withdrawl(){
      if(this.submitLoading){
        return;
      }
      if(!this.withdrawl_amount || this.withdrawl_amount<=0){
        this.$message.error(this.$t('withdrawl.cantbnull'));
        return;
      }
      let _balance = this.$store.state.userinfo.balance;
      if(this.withdrawl_amount*1000000 > _balance){
          this.$message.error(this.$t('withdrawl.lackOfBalance'));
          return;
      }
      // let u_balance = this.$store.state.userinfo.u_balance;
      // if(this.wtype==1 && this.withdrawl_amount*1000000 > u_balance){
      //     this.$message.error(this.$t('withdrawl.lackOfBalance'));
      //     return;
      // }
      this.submitLoading = true;
      this.$http.call(this.$http.api.withdrawl,{
        data:{amount: this.withdrawl_amount}
      }).then(res=>{
        console.log(res);
        this.submitLoading = false;
        let resp = res.data;
        if(resp.code=='200'){
          this.$message.success(resp.data);
          this.$store.commit('setBtmBox', false);
          this.$store.commit('setMask', false);
          this.$router.go(0);
        }else{
          this.$message.error(resp.data);
        } 
      },res=>{
        console.log('something goes wrong...');
        console.log(res.response);
        this.submitLoading = false;
        if(res.response.status == '401'){
            this.$store.commit('setToken','');
            this.$router.push({name:'login'});
        }
      }).then(()=>{
          
      });
    }
  }
}
</script>
<style lang="less">
.withdrawl{
  // padding: 20px 15px;
  // .ant-input,.ant-input-disabled,.ant-input[disabled]{
  //   background-color: @bg-primary;
  //   border:0;
  //   color: @black;
  // }
  // .label{
  //   color: #000;
  // }
  // .types{
  //   display: flex;
  //   justify-content: flex-end;
  // }
  // .btn{
  //   padding: 3px 7px;
  //   border-radius: 0;
  // }
}

</style>
