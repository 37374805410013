import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};
Vue.use(VueRouter);

const Register = () => import ('../views/Register');
const Login = () => import ('../views/Login');
const Invites = () => import ('../views/Invites');
const Wallet = () => import ('../views/Wallet');
const Insurance = () => import ('../views/Insurance');
const Insurances = () => import ('../views/Insurances');
const InsuranceProfits = () => import ('../views/InsuranceProfits');
const InsuranceDividends = () => import ('../views/InsuranceDividends');
const Home = () => import ('../views/Home');
const Share = () => import ('../views/Share');
const Article = () => import ('../views/Article');
const Withdrawl = () => import ('../views/Withdrawl');
const Security = () => import ('../views/Security');
const Card = () => import ('../views/Card');

const routes = [{
    path: '/',
    name: 'home',
    component: Home,
    meta:{
        // 页面标题title
        title: 'BCI'
    }
}, {
    path: '/register/:i?',
    name: 'register',
    component: Register,
}, {
    path: '/login',
    name: 'login',
    component: Login,
}, {
    path: '/share',
    name: 'share',
    component: Share,
}, {
    path: '/article/:id?',
    name: 'article',
    component: Article
}, {
    path: '/security',
    name: 'security',
    component: Security
}, {
    path: '/card',
    name: 'card',
    component: Card
}, {
    path: '/wallet',
    name: 'wallet',
    component: Wallet
}, {
    path: '/withdrawls',
    name: 'withdrawls',
    component: Withdrawl
}, {
    path: '/insurance',
    name: 'insurance',
    component: Insurance
},{
    path: '/insurances',
    name: 'insurances',
    component: Insurances
}, {
    path: '/insuranceProfits/:id?',
    name: 'insuranceProfits',
    component: InsuranceProfits
}, {
    path: '/insuranceDividends',
    name: 'insuranceDividends',
    component: InsuranceDividends
}, {
    path: '/invites/:paddress?',
    name: 'invites',
    component: Invites
}];

const router = new VueRouter({
    mode: "hash",
    routes
})

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    // if (to.meta.title) {
    //   document.title = to.meta.title
    // }
    let token = store.state.token;
    let noNeedLogin = ['login','register','home'];
    if (!token && noNeedLogin.indexOf(to.name) == -1) {
        return next({ name: 'login' });
    }
    if (token && (to.name == 'login' || to.name=='register')){
        return next({ name: 'home'});
    }
    next();
  })
export default router;