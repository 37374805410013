module.exports = {
    'common': {
        'currency': 'BCI',
        'submit': 'Submit',
        'register_btn': 'Register',
        'login_btn': 'Login',
        'logout': 'Logout',
        'more': 'MORE',
        'submitSuccess': 'submit success',
        'copySuccess':'copy success',
        'commingsoon': 'Coming soon...',
        'lackOfBalance': 'Lack of balance',
    },'register': {
        'title': 'Register',
        'label': {
            'referer': 'Referee',
            'account': 'Your Wallet',
            'password': 'Password',
            'email': 'Email',
        },
        'tips': {
            'iptError': 'Invalid input',
            'diff_password': 'Password not consistent',
        },
        'already_registered': 'Already have an account?'
    },'login': {
        'title': 'Login',
        'label': {
            'account': 'Wallet Address',
            'password': 'Password',
        },
        'forget': 'Forget password?',
        'tips': {
            'iptError': 'Invalid input',
            'forget': 'Contact customer service to retrieve it',
        }
    },'logo': {
        'myAssets': 'Assets',
        'myInsurance': 'Insurance',
        'language': 'Language',
        'contactUs': 'Contact Us',
        'myTeam': 'Team',
        'logout': 'Logout'
    },'wallet':{
        'title': 'Wallet',
        'amountLabel': 'Assets Balance',
        'noFundpassText': 'You have not set a fund password',
        'goSet': 'Go Set',
        'withdrawl':{
            'btn': 'Withdraw',
            'account': 'Wallet Address',
            'amount': 'Quantity',
            'password': 'Fund Password',
            'canuse': 'Balance',
            'deposit': 'Deposit',
            'depositAmount': 'Amount',
        },
        'typeText': [
            'Release','Sharing Rewards','Shareholder dividends','Savings dividend','Reconciliation of accounts','Savings withdrawal','Carry-over appreciation','Insurance Policy mortgage','Cancel Insurance','Draw policy interest','Balance withdrawal','Return'
        ]
    },'security':{
        'title': 'Security',
        'fundPass': 'Fund Password',
        'loginPass': 'Login Password',
        'oldPassword': 'Old Password',
        'password': 'New Password',
        'cpassword': 'Confirm',
        'cantbnull': 'Please enter both the original password and the new password',
        'diffrentPassword':'Password not consistent',
        'needFundpass': 'Please enter the fund password'
    },'records':{
        'title': 'Records',
    },'profit':{
        'title': 'Profits',
        'community': 'Community',
        'node': 'Node',
        'levelReward':'Level {level}',
        'nodeRewardType':["Level","SameLevel"]
    },'withdrawl':{
        'title': 'Withdraw',
        'lackOfBalance': 'Insufficient balance',
        'cantbnull': 'Please enter the withdrawal amount and fund password',
        'forget':'Forget?',
        'forgetPassTips':'Reset fund password in user page',
        'placeholder': 'Amount you want to withdraw',
        'amount':'Amount',
        'status':["Pending","passed","Rejected"],
        'statusColor':['','green','red'],
    },'team':{
        'title': 'My Team',
        'regTime': 'Register Time',
    },'share':{
        'title': 'Invite friends',
        'text': 'Join us',
        'longtap': 'Press and hold to copy the QR code',
        'or': 'OR',
        'copy': 'Copy URL address'
    },'insurance':{
        'title': 'BCI Insurance',
        'serviceBy': 'SERVICE BY BCI LAB',
        'step2': {
            'title': 'Required reading clause',
            'pleaseRead': 'Please read the terms',
            'confirmAddress': 'Click to confirm your policyholder/beneficiary wallet address',
            'agreeBtn': 'Agree and continue',
            'signTips': 'We will activate your SID for electronic signature'
        },
        'step3': {
            'labels': ['Double check your policyholder/beneficiary wallet address','Amount','Period'],
            'btn': 'SUBMIT'
        },
        'status': ['Cooling-off period','In effect','In pledge','Have expired','Completed','Cancelled','Invalid'],
        'types': ['Early bird program','Professional plan'],
        'prev': 'PREV',
        'myInsurance': {
            'title': 'My Insurance',
            'payout': 'Dividend paid out',
            'extracted': 'Extracted',
            'maturity': 'Maturity time',
            'blockNumber': 'Block number',
            'hash': 'Hash',
            'extractBtn': 'Extract',
            'terminateBtn': 'Terminate',
            'loanBtn': 'Loan',
            'repayBtn': 'Repay',
            'models': {
                'extract': {
                    'title': 'Draw interest',
                    'ps0': 'Current interest {currentInterest}',
                    'ps1': 'Do you want to charge interest to the account balance?'
                },
                'terminate': {
                    'title': 'Terminate insurance',
                    'ps0': 'You are terminating your policy {insuranceId}',
                    'ps1': 'The policy type is {type}, The estimated return is {interest}',
                    'ps2': 'After termination, you will not be able to receive income and will no longer be protected. Do you confirm termination?',
                },
                'loan': {
                    'title': 'Pledged loan',
                    'ps0': 'Pledge insurance policy #{insuranceId} for a loan',
                    'ps1': 'A {credit} credit score is required',
                    'ps2': 'After passing, you will receive {amount}USDT',
                },
                'release': {
                    'title': 'Release insurance policy',
                    'ps0': 'Pay off the loan to release insurance policy #{insuranceId}',
                    'ps1': 'The loan of {amount}usdt should be repaid'
                }
            }
        },
        'profits': {
            'title': 'Profits'
        },
        'dividends': {
            'title': 'Dividend record'
        }
    },'langs':[
        {'title': 'English','code': 'en'},
    ]
}