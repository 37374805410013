export default {
    login: {
        method: 'post',
        url: '/login',
        hasToken: false
    },
    register: {
        method: 'post',
        url: '/register',
        hasToken: false
    },
    logout:{
        method: 'delete',
        url: '/logout',
        hasToken: true
    },
    userinfo: {
        method: 'post',
        url: '/userinfo',
        hasToken: true
    },
    teams: {
        method: 'post',
        url: '/teams',
        hasToken: true
    },
    ulogs: {
        method: 'post',
        url: '/ulogs',
        hasToken: true
    },
    flows: {
        method: 'post',
        url: '/flows',
        hasToken: true
    },
    profits: {
        method: 'post',
        url: '/profits',
        hasToken: true
    },
    nodesinfo: {
        method: 'post',
        url: '/depositNodes',
        hasToken: true
    },
    
    records: {
        method: 'post',
        url: '/records',
        hasToken: true
    },
    wallets: {
        method: 'post',
        url: '/wallets',
        hasToken: true
    },
    withdrawl: {
        method: 'post',
        url: '/withdrawl',
        hasToken: true
    },
    withdrawls: {
        method: 'post',
        url: '/withdrawls',
        hasToken: true
    },
    policy: {
        method: 'post',
        url: '/policy',
        hasToken: true
    },
    changePass: {
        method: 'post',
        url: '/changePassword',
        hasToken: true
    },
    fundPass: {
        method: 'post',
        url: '/fundPassword',
        hasToken: true
    },
    insuranceProfits: {
        method: 'post',
        url: '/insuranceProfits',
        hasToken: true
    }, insuranceDividends: {
        method: 'post',
        url: '/insuranceDividends',
        hasToken: true
    }, insurances: {
        method: 'post',
        url: '/insurances',
        hasToken: true
    }, insuranceLoan: {
        method: 'post',
        url: '/insuranceLoan',
        hasToken: true
    }, insuranceTerminate: {
        method: 'post',
        url: '/insuranceTerminate',
        hasToken: true
    }, insuranceExtract: {
        method: 'post',
        url: '/insuranceExtract',
        hasToken: true
    },
    
}