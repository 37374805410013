<template>
  <div class="app">
    <div class="main" :class="isBlur">
      <router-view :key="$route.fullPath" />
    </div>
    <div v-if="$store.state.showLoading" class="loading-mask">
      <div class="mask"></div>
      <a-spin size="large" />
    </div>
    <div v-if="$store.state.showMask" class="gMask" @click="closeMask"></div>
    <div v-if="$store.state.showBtmBox" class="btm-box animate__animated animate__slideInUp">
      <div v-if="$store.state.showSetFundpassBox" class="no-fundpass-box">
        <div class="pd-y-2 text">{{$t('wallet.noFundpassText')}}</div>
        <div class="btn-primary" @click="goSetFundpass">
          {{$t('wallet.goSet')}}
        </div>
      </div>
      <Withdrawl v-if="$store.state.showWithdrawlBox" />
    </div>
  </div>
</template>

<script>

import Withdrawl from './components/Withdrawl.vue';

export default {
  name: 'App',
  components: {
    Withdrawl
  },
  data(){
    return {
    }
  },
  computed:{
    isBlur(){
      return this.$store.state.showMask?'blur':'';
    },
  },
  created(){
    this.$i18n.locale = this.$store.state.lang;
  },
  mounted(){
  },
  methods: {
    goSetFundpass(){
      this.closeMask();
      this.$router.push({name:'security'});
    },
    closeMask(){
      this.$store.commit('setBtmBox', false);
    },
  }
}
</script>

<style lang="less">
@font-face { 
    font-family: 'manroper';
    src: url('./assets/font/Manrope-Regular.ttf');
}
.app {
  font-family: Manrope,manroper,Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: @black;
  font-size: @base-font-size;
  height:100vh;
  // background: url('~@/assets/bg.png') no-repeat;
  // background-size: cover;
}
.main{
  background: url('~@/assets/bg.png') no-repeat;
  background-size: cover;
  min-height: 100%;
  // overflow-y: scroll;
}
</style>
