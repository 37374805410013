// import router from '../router';
import axios from 'axios';
import { extend } from '../utils/funs/copy';
import { isEmpty } from '../utils/funs/types';
import config from '../config';
import store from '../store';

/*
 * 加载中动画
 */
// let counter = 0;
// let loadinginstace = null;
function showLoading(){
	// counter++;
	// loadinginstace = Vue.prototype.$toast({
    //     duration: 0,
    //     message: 'Loading...',
    //     forbidClick: true,
    //     loadingType: 'spinner',
    // });
    // console.log(url)
    store.commit('setLoading', true)
}
function hideLoading(){
	// counter--;
	// if (counter == 0 && loadinginstace) {
	// 	loadinginstace.clear();
	// }
    // console.log(url)
    store.commit('setLoading', false)

}

export default function call(api, options) {
    if (!api) {
        throw new Error("未提供有效的api信息");
    }

    /*
     * 默认参数及用户参数合并
     */
    let defaults = {
        // axios需要的参数
        baseURL: config._API_URL,
        url: '',
        method: 'get',
        data: null,
        params: null,
        timeout: '3000',
        headers: {
            'Content-Type': 'application/json'
        },
        // 自定义参数
        hasToken: true
    }
    extend(defaults, api, options);

    /*
     * 处理axios需要的参数
     */
    // 给请求加上时间戳
    if (defaults.url.indexOf('?') > -1) {
        defaults.url += "&t=" + new Date().getTime();
    } else {
        defaults.url += "?t=" + new Date().getTime();
    }
    // 请求头携带token
    let _token = store.state.token;
    if (defaults.hasToken && !isEmpty(_token)) {
        defaults.headers.Authorization = "Bearer " + _token;
    }

    /*
     * 请求拦截器
     */
    axios.interceptors.request.use(config => {
        showLoading();
        return config;
    }, err => {
        hideLoading();
        return Promise.reject(err);
    });

    /*
     * 响应拦截器
     */
    axios.interceptors.response.use(res => {
        hideLoading();
        return res;
    }, error => {
        hideLoading();
        return Promise.reject(error);
    });
    
    return axios(defaults);
}