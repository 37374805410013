import Vue from 'vue';
import Vuex from 'vuex';
import { getSession, setSession, getLocalStorage, setLocalStorage } from '../utils/funs/storage';
import config from '../config';
Vue.use(Vuex);

export default new Vuex.Store({
	state: {
        lang: getLocalStorage(config._LANG_KEY) || config._DEFAULT_LANG,
		token: getSession(config._TOKEN_KEY),
		showLoading: false,
        userinfo: getLocalStorage("userinfo") || {
			address: '', balance: 0.00, node_level: 0
		},
		showSetFundpassBox: false,
		showWithdrawlBox: false,
		showLangBox: false,
		showMask: false,
		showBtmBox: false,
	},
	mutations: {
		setMask(state, show){
			state.showMask = show;
		},
		setBtmBox(state, show){
			state.showBtmBox = show;
			if(!show){
				state.showMask = false;
				state.showSetFundpassBox=false;
				state.showWithdrawlBox=false;
				state.showLangBox=false;
			}else{
				state.showMask = true;
			}
		},
		setShowSetFundpassBox(state, show){
			state.showSetFundpassBox = show;
		},
		setShowWithdrawlBox(state, show){
			state.showWithdrawlBox = show;
		},
		setShowLangBox(state, show){
			state.showLangBox = show;
		},
		setLang(state, lang) {
			state.lang = lang || config._DEFAULT_LANG;
			setLocalStorage(config._LANG_KEY, lang);
		},
		setToken(state, token) {
			state.token = token;
			setSession(config._TOKEN_KEY, token);
		},
		setLoading(state, loading){
			state.showLoading = loading
		},
		setUserinfo(state, userinfo) {
			state.userinfo = userinfo;
			setLocalStorage("userinfo", userinfo);
		},
	},
	actions: {

	},
	modules: {

	}
});
